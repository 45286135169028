<template>

  <div class="join">
    <div class="join-con">
      <h1 class="joinlogo"><img src="@/assets/img/peanutlogo.svg"/></h1>
      <h2>{{ $t('front.common.signup') }}</h2>
      <div class="join-in">
        <div class="joininputwrap">
           <span class="jointxt">{{ $t('front.common.memId') }}</span>
           <input class="joininput" type="text" v-model="model.memId" :readonly="true" @focus="onFocus" :placeholder="$t('front.signup.memIdPlaceholder')"/>
        </div>
        <div class="joininputwrap">
           <span class="jointxt">{{ $t('front.common.password') }}</span>
           <input class="joininput" type="password" v-model="model.memPass" :placeholder="$t('front.signup.passwordPlaceholder')"/>
        </div>
        <div class="joininputwrap">
           <span class="jointxt">{{ $t('front.mypage.passwordOk') }}</span>
           <input class="joininput" type="password" v-model="model.memPassCheck" :placeholder="$t('front.signup.passwordPlaceholder')"/>
        </div>
        <div class="joininputwrap">
           <span class="jointxt">{{ $t('front.common.cashOutPass') }}</span>
           <input class="joininput" maxlength="4" inputmode="numeric" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  pattern="[0-9]*" id="cashOutPass" type="text" v-model="model.cashOutPass" :placeholder="$t('front.signup.cashOutPassPlaceholder')"/>
        </div>
        <div class="joininputwrap">
           <span class="jointxt">{{ $t('front.common.nickName') }}</span>
           <input class="joininput" type="text" v-model="model.memNick" :placeholder="$t('front.signup.nickNamePlaceholder')"/>
        </div>
        <div class="joininputwrap">
           <span class="jointxt">{{ $t('front.common.phone') }}</span>
           <input class="joininput" type="text" v-model="model.memPhone" :placeholder="$t('front.signup.phonePlaceholder')"/>
        </div>
        <div class="joininputwrap" v-if="!joinRecommenderId">
           <span class="jointxt">{{ $t('front.signup.recommandIdPlaceholder') }}</span>
           <input class="joininput" type="text" v-model="model.recommenderId" :placeholder="$t('front.common.recommandId')"/>
        </div>
        <div class="joininputwrap" v-else>
           <span class="jointxt">{{ $t('front.signup.recommandIdPlaceholder') }}</span>
           <div class="recommend">{{joinRecommenderId}}</div>
        </div>
        <div class="joininputwrap">
          <span class="jointxt">{{ $t('front.common.bankSelect') }}</span>
          <div>
             <select v-model="model.bank">
               <option selected :value="''" disabled>{{ $t('front.common.bankSelect') }}</option>
               <template v-for="item in bankList" v-bind:key="item.bankCode">
                <option :value="item.bankCode">{{ item.bankNameKr }}</option>
               </template>
             </select>
             <input class="joininput" type="text" :placeholder="$t('front.common.bankName')" v-model="model.memName"/>
          </div>
        </div>
        <div class="joininputwrap">
           <span class="jointxt">{{ $t('front.common.bankAcc') }}</span>
           <input class="joininput" type="number" :placeholder="$t('front.common.bankAccOnlyNumber')" v-model="model.bankacc"/>
        </div>
        <!--ul class="sns">
          <li><a><img src="@/assets_mobile/img/cacao.png"><span class="br">test1234</span></a></li>
          <li><a><img src="@/assets_mobile/img/telegram.png"><span class="bl">test1234</span></a></li>
        </ul-->
      </div>
      <a class="joinbtns" @click="onSubmit('mobileMain')">{{ $t('front.common.signup') }}</a>
    </div>
  </div>
</template>

<script>
import join from '@/components/member/Join'

export default {
  name: 'join',
  mixins: [join]
}
</script>
<style scoped>
#cashOutPass {
  -webkit-text-security: disc;
}
</style>
<style scoped src="@/styles_mobile/join.css"></style>
<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
